import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
    message: "",
    navigationFlag : false
  },
  reducers: {
    loginNavigationSet : (state)=>{
      state.navigationFlag = true;
    },
    loginNavigationUnset : (state)=>{
      state.navigationFlag = false;
    },
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload.user;
      state.message = action.payload.msg;
    },
    loginFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.message = action.payload;
      console.log(action.payload)
    },
    sendOtpSuccess: (state, action) => {
      state.isFetching = false;
      state.message = action.payload;
    },
    resetUser: (state) => {
      state.currentUser = null;
    },
    resetOthers: (state) => {
      state.isFetching = false;
      state.error = false;
      state.message = '';
    }
  },
});

export const { loginStart, loginSuccess, loginFailure, sendOtpSuccess, resetUser, resetOthers,loginNavigationSet,loginNavigationUnset } = userSlice.actions;
export default userSlice.reducer;