import React from 'react';
import styled from 'styled-components';
import { mobile } from '../responsive';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const RefundPolicy = () => {
  return (
    <div>
      <Navbar />
      <Container>
        <TopContainer>
          <Title>Refund Policy</Title>
        </TopContainer>
        <Content>
          <Paragraph>At Chamak Saathi, we are committed to delivering high-quality services. Our refund policy is designed to ensure clear communication and satisfaction for our clients.</Paragraph>

          <SectionTitle>Cancellations</SectionTitle>
          <Paragraph>Services can be cancelled up to 2 days before the scheduled delivery without incurring any penalties. Cancellations made after this period may be subject to a 70% cancellation fee.</Paragraph>

          <SectionTitle>Refunds</SectionTitle>
          <Paragraph>We aim to meet or exceed our clients’ expectations. If you are dissatisfied with the service received, please contact us within 3 days of service completion. Eligibility for refunds will be considered on a case-by-case basis, focusing on service delivery and satisfaction criteria.</Paragraph>

          <SectionTitle>Processing</SectionTitle>
          <Paragraph>Approved refunds will be processed via the original payment method or as credit towards future services, as per the client's preference. Please allow 2 business days for the refund to be processed.</Paragraph>

          <SectionTitle>Changes to the Policy</SectionTitle>
          <Paragraph>This policy is subject to change. We recommend clients review it periodically.</Paragraph>

          <SectionTitle>Contact Us</SectionTitle>
          <Paragraph>For questions or concerns about our refund policy or your service, please contact us at contact@chamaksaathi.com.</Paragraph>
        </Content>
      </Container>
      <Footer />
    </div>
  );
};

const Container = styled.div`
  width: 90%;
  padding: 70px 80px 30px 80px;
  margin: 20px auto;
  ${mobile({ padding: '30px 10px', width: '100%' })}
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 40px;
  margin: 0;
`;

const Content = styled.div`
  margin: 20px 0;
`;

const SectionTitle = styled.h3`
  font-weight: 500;
  margin: 20px 0 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;

export default RefundPolicy;
