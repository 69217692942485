import { createSlice } from "@reduxjs/toolkit";

const serviceSlice = createSlice({
    name: "service",
    initialState: {
        isFetching : false,
        allServices : []
    },
    reducers: {
        serviceFetching : (state)=>{
            state.isFetching = true;
        },
        getServices: (state, action) => {
            state.isFetching = false;
            state.allServices = action.payload;
        },
    }
});

export const { getServices,serviceFetching } = serviceSlice.actions;
export default serviceSlice.reducer;