import React, { useState } from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { faqData } from '../NearMeContent';
import { mobile } from '../responsive';

const Container = styled.div`
  // width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  box-sizing : border-box;
  // margin-left: 80px;
  margin: 10px 80px 20px;
  padding-top: 30px;
  ${mobile({marginLeft:"auto",width:"100%"})}
`;

const QueBox = styled.div`
// background-color:red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  // padding: 2px;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const FaqBox = styled.div`
  width: 87%;
  height: auto;
  margin-bottom: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  ${mobile({width:"96%",margin:"auto"})}
`;

const Answer = styled.p`
  max-height: ${({ expanded }) => (expanded ? '500px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  margin: 0;
  font-size:18px;
  padding-left:10px;
  padding-right:10px;

`;

function Faq() {
  const [expanded, setExpanded] = useState([]);

  const toggleExpand = (index) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };


  return (
    <>
      <h2 style={{ fontSize: '30px', margin: '10px 80px 10px', paddingTop: '40px' }}>
        FAQ's
      </h2>
      <Container>
        {faqData.map((faq, index) => (
          <FaqBox key={index}>
            <QueBox onClick={() => toggleExpand(index)}>
              <h3>{faq.question}</h3>
              {expanded[index] ? (
                <RemoveIcon style={{ fontSize: '30px',paddingRight:'20px' }} />
              ) : (
                <AddIcon style={{ fontSize: '30px',paddingRight:'20px' }} />
              )}
            </QueBox>
            <Answer expanded={expanded[index]}>{faq.answer}</Answer>
          </FaqBox>
        ))}
      </Container>
    </>
  );
}

export default Faq;
