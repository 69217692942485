import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { mobile } from '../responsive';
import Icons from '../icons';
import { useSelector, useDispatch } from 'react-redux';
import { addAddress } from '../redux/apiCalls';
const { ArrowBackIcon } = Icons;

const BookingTimings = ({ formData, setFormData, handleNext, onBack }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const addresses = useSelector((state) => state.user?.currentUser?.addresses || []);
  const [selectedAddress, setSelectedAddress] = useState((addresses !== null && addresses.length !== 0) ? addresses[0] : null);
  const [serviceTime, setServiceTime] = useState('08:00 AM');
  const [startDate, setStartDate] = useState('');
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [pincode, setPincode] = useState('');

  const handleSaveTimings = () => {
    console.log(`Service Time ${serviceTime} \n Start Date: ${startDate}`)
    if (serviceTime && startDate && selectedAddress) {
      const updatedFormData = {
        ...formData,
        bookingTimings: {
          serviceTiming: serviceTime,
          startDate: startDate,
        },
        selectedAddress: selectedAddress
      };
      setFormData(updatedFormData);
      console.log('address', addresses);
      console.log('Updated Form Data: ', updatedFormData);
      handleNext();
    } else {
      // Handle validation or show an error message
    }
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const year = tomorrow.getFullYear();
    const month = (tomorrow.getMonth() + 1).toString().padStart(2, '0');
    const day = tomorrow.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleNewAddress = () => {
    setAddNewAddress(true);
  };

  const handleAddressSubmit = (e) => {
    e.preventDefault();
    addAddress(dispatch, user._id, user.accessToken, { address: address, pinCode: pincode, city: city });
    setAddNewAddress(false);
    // Update selected address after adding new address
    setSelectedAddress({ address: address, pinCode: pincode, city: city });
  };

  const handleAddressChange = (event) => {
    setSelectedAddress(JSON.parse(event.target.value));
  };

  const convertTimeToRangeValue = (time) => {
    const [hours, minutes] = time.split(':');
    const period = time.split(' ')[1];
    let hourValue = parseInt(hours);
    if (period === 'PM' && hourValue !== 12) hourValue += 12;
    if (period === 'AM' && hourValue === 12) hourValue = 0;
    return (hourValue - 8) * 2 + (parseInt(minutes) === 30 ? 1 : 0);
  };

  const convertRangeValueToTime = (value) => {
    const totalHours = Math.floor(value / 2) + 8;
    const hours = totalHours % 12 || 12;
    const minutes = value % 2 === 0 ? '00' : '30';
    const period = totalHours < 12 || totalHours === 24 ? 'AM' : 'PM';
    return `${hours.toString().padStart(2, '0')}:${minutes} ${period}`;
  };

  const handleTimeChange = (event) => {
    setServiceTime(convertRangeValueToTime(event.target.value));
  };

  return (
    <Container>
      <TopContainer>
        <Back onClick={onBack}>
          <ArrowBackIcon />
        </Back>
        <Title>Booking Timings</Title>
      </TopContainer>
      <InputContainer>
        <Label>Service Time</Label>
        <RangeInput
          type="range"
          min="0"
          max="24"
          value={convertTimeToRangeValue(serviceTime)}
          onChange={handleTimeChange}
        />
        <TimeDisplay>{serviceTime}</TimeDisplay>
      </InputContainer>
      <InputContainer>
        <Label>Start Date</Label>
        <Input
          type="date"
          value={startDate}
          min={getTomorrowDate()}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </InputContainer>
      <InputContainer>
        <Label>Select Address</Label>
        {(addresses !== null && addresses.length !== 0) && addresses?.map((add, index) => (
          <Address key={index}>
            <input
              type="radio"
              name="address"
              value={JSON.stringify(add)}
              id={`address-${index}`}
              checked={selectedAddress && selectedAddress.address === add.address}
              onChange={handleAddressChange}
            />
            <label htmlFor={`address-${index}`}>
              {`${add.address}, ${add.city}, ${add.pinCode}`}
            </label>
          </Address>
        ))}
        {addNewAddress
          ?
          <>
            <br />
            <InputAdd value={address} onChange={(e) => setAddress(e.target.value)} type='text' placeholder='Address' />
            <InputAdd value={city} onChange={(e) => setCity(e.target.value)} type='text' placeholder='City' />
            <InputAdd value={pincode} onChange={(e) => setPincode(e.target.value)} type='text' placeholder='Pincode' />
            <Button onClick={handleAddressSubmit}>Save Address</Button>
          </>
          :
          <NewAdd onClick={handleNewAddress}> + add new address</NewAdd>}
      </InputContainer>
      <ButtonContainer>
        <Button onClick={handleSaveTimings}>Save & Next</Button>
      </ButtonContainer>
    </Container>
  );
};

const NewAdd = styled.p`
color: #1C3879;
margin-left: 5.5px;
margin-top: 8px;
font-size: 18px;
cursor: pointer;
`;

const Warn = styled.h3`
 font-weight: 500;
`;

const Address = styled.div`
margin-top: 10px;
`;

const Container = styled.div`
  width: 90%;
  padding: 30px 40px;
  margin: 20px 0 20px;
  border-radius: 15px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  ${mobile({ padding: '30px 10px', width: '100%' })}
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const Back = styled.span`
  display: flex;
  height: 30px;
  width: 30px;
  color: white;
  background-color: #4477CE;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Title = styled.h1`
  font-weight: 200;
  margin-top: 22px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  font-size: 18px;
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const InputAdd = styled.input`
  width: 25%;
  height: 30px;
  margin-top: 7px;
  margin-right: 7px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const RangeInput = styled.input`
  width: 100%;
  margin-top: 10px;
`;

const TimeDisplay = styled.div`
  margin-top: 10px;
  font-size: 16px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  ${mobile({ padding: '0 20px' })}
`;

const Button = styled.button`
  height: 40px;
  width: 120px;
  color: white;
  font-size: 15px;
  background-color: #4477CE;
  border-radius: 5px;
  cursor: pointer;
  border: none;
`;

export default BookingTimings;
