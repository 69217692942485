import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import AllServices from './pages/AllServices';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Service from './pages/Service';
import Profile from './pages/Profile';
import Success from './pages/Success';
import Failure from './pages/Failure';
import { useSelector } from 'react-redux';
// import Spinner from './components/Spinner';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import TestPayment from './pages/TestPayment';
import { ToastContainer, Zoom } from 'react-toastify';
import Subscription from './pages/Subscription';
import NearBy from './pages/NearBy';
// import 'react-toastify/dist/ReactToastify.css';

function App() {
  const user = useSelector((state) => state.user.currentUser?.accessToken);
  const isFetching = useSelector((state) => state.user.isFetching);

  return (
    <>
      {/* {isFetching && <Spinner />} */}
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services/all' element={<AllServices />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/login' element={<Login />} />
          <Route path='/services/:id' element={<Service />} />
          <Route path='/success/:bookingId' element={<Success />} />
          <Route path='/failure' element={<Failure />} />
          <Route path='/subscription/details/all' element={<Subscription/>} />
          {user && <Route path='/profile' element={<Profile />} />}
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/maid-service-near-me' element={<NearBy/>} />
        </Routes>
      </Router>
      <ToastContainer
        autoClose={1500}
        closeButton={false}
        limit={2}
        draggable={false}
        transition={Zoom}
        position="top-center"
      />
    </>
  );
}

export default App;
