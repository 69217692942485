import React from 'react';
import Navbar from '../components/Navbar';
import AllServicesComponent from '../components/AllServicesComponent';
import Footer from '../components/Footer';
import TrailStrip from '../components/TrailStrip';
import Whatsapp from '../components/Whatsapp';

export default function AllServices() {
  return (
    <div>
      <Navbar />
      <TrailStrip/>
      <AllServicesComponent />
      <Footer />
      <Whatsapp/>
    </div>
  )
}
