import React from 'react';
import styled from 'styled-components';
import { mobile } from '../responsive';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const TermsOfService = () => {

  return (
    <div>
        <Navbar />
        <Container>
        <TopContainer>
            <Title>Terms of Service</Title>
        </TopContainer>
        <Content>
            <Paragraph>Last updated: 25 March 2024</Paragraph>
            <Paragraph>Welcome to Chamak Saathi! We provide domestic services including cooking, cleaning, and babysitting, subject to the following Terms of Service (ToS), which may be updated by us from time to time without notice to you...</Paragraph>

            <SectionTitle>1. Service Description</SectionTitle>
            <Paragraph>Chamak Saathi provides various domestic services through its website. These services are detailed on our website and may be updated, modified, or discontinued at our discretion.</Paragraph>

            <SectionTitle>2. User Accounts</SectionTitle>
            <Paragraph>To access certain services, you may be required to create an account with us. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</Paragraph>

            <SectionTitle>3. Privacy Policy</SectionTitle>
            <Paragraph>Your privacy is very important to us. Our Privacy Policy, which details how we collect, use, and protect your information, is incorporated into these Terms of Service. Please read the Privacy Policy carefully.</Paragraph>

            <SectionTitle>4. Service Modifications</SectionTitle>
            <Paragraph>Chamak Saathi reserves the right at any time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.</Paragraph>

            <SectionTitle>5. User Conduct</SectionTitle>
            <Paragraph>You are solely responsible for your conduct and any data, text, information, usernames, graphics, photos, profiles, audio and video clips, links, and other content or materials that you submit, post, and display on Chamak Saathi.</Paragraph>

            <SectionTitle>6. Limitation of Liability</SectionTitle>
            <Paragraph>You expressly understand and agree that Chamak Saathi shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use of or inability to use the service.</Paragraph>

            <SectionTitle>7. Indemnity</SectionTitle>
            <Paragraph>You agree to indemnify and hold Chamak Saathi, its officers, directors, employees, and agents, harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Service, your violation of the ToS, or your violation of any rights of another.</Paragraph>

            <SectionTitle>8. Governing Law</SectionTitle>
            <Paragraph>These Terms of Service shall be governed by the laws of [Your Country/State] without regard to its conflict of law provisions.</Paragraph>

            <SectionTitle>9. Changes to the Terms of Service</SectionTitle>
            <Paragraph>We reserve the right, at our sole discretion, to modify or replace these Terms of Service at any time.</Paragraph>
        </Content>
        </Container>
        <Footer />
    </div>
  );
};

const Container = styled.div`
  width: 90%;
  padding: 70px 80px 30px 80px;
  margin: 20px auto;
  ${mobile({ padding: '30px 10px', width: '100%' })}
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 40px;
  margin: 0;
`;

const Content = styled.div`
  margin: 20px 0;
`;

const SectionTitle = styled.h3`
  font-weight: 500;
  margin: 20px 0 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;



export default TermsOfService;
