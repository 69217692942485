import React from 'react';
import Icons from '../icons';
import styled from "styled-components";
import { mobile } from "../responsive";
import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom';

const { FacebookOutlinedIcon, InstagramIcon, MailOutlineIcon, PhoneIcon, LinkedInIcon } = Icons;

const Container = styled.div`
  display: flex;
  background-color: #f9f5eb;
  margin-top: 20px;
  padding: 20px 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-top: 1px solid gray; */
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${mobile({ 'flex-direction': 'column-reverse' })}
`;

const Left = styled.div`
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  padding: 20px;
  ${mobile({ 'align-items': 'center' })}
`;

const Logo = styled.img`
  width: 180px;
  margin: 15px 0;
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: #${(props) => props.color};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 20px; */

  a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

const Center = styled.div`
  /* flex: 1; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${mobile({ 'align-items': 'center' })}`;

const Title = styled.h3`
  margin-bottom: 30px;
  ${mobile({ marginBottom: '15px', })}
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  ${mobile({ flexDirection: 'column', alignItems: 'center', textAlign: 'center' })}
`;

const ListItem = styled(Link)`
  width: 50%;
  padding: 0;
  margin-bottom: 10px;
  color: #65451f;
  text-decoration: none;
`;

const Right = styled.div`
  /* flex: 1; */
  padding: 20px;
  display: flex;
  flex-direction: column;
  ${mobile({ 'align-items': 'center' })}
`;

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: #65451f;

  ${mobile({ marginBottom: '5px', })}
`;

const CopyrightContainer = styled.div`
  margin-top: 20px;
`;

const CopyrightText = styled.div`
  color: #65451f;
`;

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <Logo src={logo} />
          <SocialContainer>
            <SocialIcon color="3B5999">
              <a href="https://www.facebook.com/chamaksaathi/" target="_blank"><FacebookOutlinedIcon /></a>
            </SocialIcon>
            <SocialIcon color="E4405F">
              <a href="https://www.instagram.com/chamaksaathi/" target="_blank"><InstagramIcon /></a>
            </SocialIcon>
            <SocialIcon color="0A66C2">
              <a href="https://www.linkedin.com/in/chamak-saathi-996125283/" target='_blank'><LinkedInIcon /></a>
            </SocialIcon>
          </SocialContainer>
        </Left>
        <Center>
          <Title>Useful Links</Title>
          <List>
            <ListItem to='/'>Home</ListItem>
            <ListItem to='/refund-policy'>Refund Policy</ListItem>
            <ListItem to='/privacy-policy'>Privacy Policy</ListItem>
            <ListItem to='/terms-of-service'>Terms of Service</ListItem>
            <ListItem to='/maid-service-near-me'>Service Near Me</ListItem>
          </List>
        </Center>
        <Right>
          <Title>Contact</Title>
          <ContactItem>
            <PhoneIcon style={{ marginRight: "10px" }} /> +91 98181 02998
          </ContactItem>
          <ContactItem>
            <MailOutlineIcon style={{ marginRight: "10px" }} /> chamaksaathi@gmail.com
          </ContactItem>
        </Right>
      </Wrapper>
      <CopyrightContainer>
        <CopyrightText>&#169; Chamak Saathi { new Date().getFullYear() }</CopyrightText>
      </CopyrightContainer>
    </Container>
  );
};

export default Footer;
