import React, { useEffect, useRef } from 'react';
import Navbar from '../components/Navbar';
import TrailStrip from '../components/TrailStrip';
import Footer from '../components/Footer';
import styled from 'styled-components';
import Testimonials from '../components/Testimonials';
import MainServices from '../components/MainServices';
import { mobile } from '../responsive';
import Faq from '../components/Faq';
import { content } from '../NearMeContent';
import Banner from '../components/Banner';
import bgnearby from "../assets/images/banner.png";
import NearByBanner from '../components/NearByBanner';
import Whatsapp from '../components/Whatsapp';

const Wrapper = styled.div`
  max-width: 1350px;
  // margin-top: 20px;
  margin: 10px 80px 20px;
  padding-top: 20px;
  border-radius: 8px;
  ${mobile({width:"95%",marginLeft:"auto",paddingLeft:"5px"})}
`;

// const Header = styled.div`
//   margin-left: 23px;
//   padding-bottom: 20px;
// `;

const Title = styled.h2`
  font-size: 33px;
  // text-align: left;
  margin-bottom: 39px;
  color: #65451f;
  ${mobile({ textAlign: 'left', marginLeft: "auto",fontSize:"25px" })};
`;

const Post = styled.div`
  // margin-top: 20px;
  // margin-bottom: 30px;
  // padding: 20px;
  background-color: #fff;
  // border-radius: 8px;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h4`
  font-size: 18px;
  color: #65451f;
  margin-bottom: 10px;
`;

const Content = styled.p`
  font-size: 16px;
  color: #65451f;
  line-height: 1.6;
`;

// const Span = styled.span`
//   border-right: 1px solid #65451f;
//   margin-right: 8px;
//   padding-right: 8px;
//   font-size: 20px;
//   cursor: pointer;
// `;

function NearBy() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  // const faqRef = useRef(null);
//   const servicesRef = useRef(null);
//   const testimonialsRef = useRef(null);

//   const scrollToSection = (ref) => {
//     const offsetTop = ref.current.getBoundingClientRect().top + window.scrollY - 70;
//     window.scrollTo({ top: offsetTop, behavior: 'smooth' });
//   };


{/* <Header>
<Span onClick={() => scrollToSection(faqRef)}>faq</Span>
<Span onClick={() => scrollToSection(servicesRef)}>services</Span>
<Span onClick={() => scrollToSection(testimonialsRef)}>testimonials</Span>
</Header> */}

  return (
    <div>
      <Navbar />
      <TrailStrip />
      {/* <Banner image={bgnearby} heading="Maid Service Near Me in Gurgoan!" para="" btnText="Call Now"/> */}
      <NearByBanner/>
      <MainServices />
      <Faq />
      <Wrapper>
        <Title>Find Trusted Maid Service Near Me: Daily Maid Service for a Clean, Fresh Home Every Day</Title>
        {content.map((item, ind) => (
          <Post key={ind}>
            <Heading>{item.heading}</Heading>
            <Content>{item.content}</Content>
          </Post>
        ))}
      </Wrapper>
      <Testimonials />
      <Footer />
      <Whatsapp/>
    </div>
  );
}

export default NearBy;

