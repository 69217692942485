import React, { useState } from "react";
import styled from "styled-components";
import { mobile } from "../responsive";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  height: 90vh;
  min-height: 650px;
  padding: 0px 0 0px 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-size: cover; 
  background-position: center;
  ${mobile({
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '10px 0 20px 20px',
  })}
`;

const InfoContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mobile({ 'width': '90%' })}
`;

const Title = styled.h1`
  font-size: 52px;
  font-weight: 600;
  margin: 0;
  ${mobile({ fontSize: '40px' })}
`;

const Desc = styled.p`
  margin: 10px 0px;
  font-size: 23px;
  color: #b99470;
`;

const Button = styled.button`
  margin: 20px 0;
  padding: 15px;
  font-size: 20px;
  color: #65451f;
  width: 50%;
  box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.25);
  background-color: #eac696;
  border-radius: 10px;
  cursor: pointer;
  border: none;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 60%;
  ${mobile({ 'width': '90%' })}
`;

const Image = styled.img`
  width:80%;
  position: absolute;
  object-fit:contain;
  right: 0;
  ${mobile({ right: '-70px', top: '-30px' })}
`;

const Banner = ({ image, heading, btnText, para }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
      window.location.href = "tel:9818102998"; 

  };
  
  return (
    <Container>
      <InfoContainer>
        <Title>{heading}</Title>
        <Desc>{para}</Desc>
        <Button onClick={handleButtonClick}>{btnText}</Button>
      </InfoContainer>
      <ImageContainer>
        <Image src={image} />
      </ImageContainer>
    </Container>
  );
};

export default Banner;
