import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from "../redux/apiCalls";
// import Spinner from "./Spinner"; 
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { resetOthers } from '../redux/userRedux';
import { publicRequest } from '../requestMethods';

const LoginForm = ({ onLogin, onSwitchForm,handleForgot,isForgot,onClose}) => {
  const navigate = useNavigate();
  const [userIdentifier, setUserIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword,setConfirmPassword] = useState('');
  const [otp,setOtp] = useState('');
  const [showOtpPopUp,setShowOtpPopUp] = useState(false);
  const [timer, setTimer] = useState(30);
  const [timerActive, setTimerActive] = useState(false);
  const dispatch = useDispatch();
  // const message = useSelector((state) => state.user.message);
  // const error = useSelector((state) => state.user.error);
  const isFetching = useSelector((state) => state.isFetching);
  // const user = useSelector((state) => state.user.currentUser);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (error) {
  //     toast.error(message);
  //   }
  //   if (user) {
  //     navigate('/profile');
  //   }
  //   dispatch(resetOthers());
  // }, [error, message]);

  useEffect(() => {
    let interval;

    if (timerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            setTimerActive(false);
            return 30; // Reset timer to 30 seconds
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerActive]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if(userIdentifier===""||password==="") return toast.warn("Fill the credentials properly");
    login(dispatch, { userIdentifier, password });
    onLogin();
  }

  const handleResetPassword = async(e)=>{
    e.preventDefault();
    if(confirmPassword===''||password===''||otp==='')return toast.warn('Fill all the Credentials');
    if(confirmPassword!==password)return toast.warn('Password and Confirm Password does not match');
    try {
      const response = await publicRequest.patch('/auth/resetpassword', {
        email: userIdentifier,
        otp,
        password
      });
      if (response.error) {
        toast.error('Incorrect OTP');
      } else if (response.data === 'password changed') {
        toast.success('Password Changed Successfully');
        onClose();
        handleForgot(false);
        setPassword('');
        setUserIdentifier('');
        setConfirmPassword('');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Incorrect OTP');
    }
    


  }

  const handleSendOtp = async(e)=>{
    e.preventDefault();
    if(userIdentifier===""||!userIdentifier.includes('@'))return toast.warn("E-mail Id must be Valid");
    try {
      
      const response = await publicRequest.post(`auth/send-email-otp?reset=true`, {email:userIdentifier});
      
      if(response.data){
        toast.success('OTP sent to Email Id')
        setTimerActive(true);
        if(!showOtpPopUp)setShowOtpPopUp(true);
      }
       // Handle response data accordingly
      // Clear form data or any other action after successful POST
    } catch (error) {
      toast.warn('user does not exist')
      // Handle error, show user feedback, etc.
    }
    
  }

  return (
    <>
      {/* <ToastContainer draggable={false} transition={Zoom} position={toast.POSITION.TOP_CENTER}/> */}
      {/* {isFetching && <Spinner />} */}
      {isForgot?
      <Form onSubmit={!showOtpPopUp?handleSendOtp:handleResetPassword}>
      <FormInput
        type="text"
        placeholder="Enter Your E-mail Id"
        value={userIdentifier}
        onChange={(e) => setUserIdentifier(e.target.value)}
      />
      {showOtpPopUp && 
        <>
        <FormInput
        type="password"
        placeholder="New Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <FormInput
        type="password"
        placeholder="Confirm New Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <FormInput
        type="text"
        placeholder='OTP'
        value={otp}
        onChange={(e)=>setOtp(e.target.value)}
      />
      </>}
    {showOtpPopUp && (timerActive?<ResendOtp>resend in {timer<10?`00:0${timer}`:`00:${timer}`}</ResendOtp>:<ResendOtp onClick={handleSendOtp}>resend OTP</ResendOtp>)}
      <FormButton type="submit" disabled={isFetching}>
        {isFetching ? 'Sending...' : !showOtpPopUp ? 'send OTP':'Set Password'}
      </FormButton>
      <SignUpLink onClick={onSwitchForm}>Or Sign Up</SignUpLink>
      
    </Form>
      :
      <Form onSubmit={handleSubmit}>
        <FormInput
          type="text"
          placeholder="E-mail or Mobile Number"
          value={userIdentifier}
          onChange={(e) => setUserIdentifier(e.target.value)}
        />
        <FormInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <ForgotPassword onClick={()=>handleForgot(true)}>forgot Password</ForgotPassword>
        <FormButton type="submit" disabled={isFetching}>
          {isFetching ? 'Logging in...' : 'Login'}
        </FormButton>
        <SignUpLink onClick={onSwitchForm}>Or Sign Up</SignUpLink>
        
      </Form>}
    </>
  );
};
const ResendOtp = styled.div`
  width:100%;
//   margin-top: 5px;
  cursor:pointer;
  color: #1C3879;
  // background-color:red;
  display:flex;
  justify-content:flex-end;
  margin-right:-19px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormInput = styled.input`
  padding: 15px;
  width: 100%;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
`;

const FormButton = styled.button`
  margin: 20px;
  // padding: 15px 20px;
  font-size: 16px;
  display:flex;
  align-items : center;
  justify-content:center;
  height : 44px;
  width:100px;
  background-color:#eac696 ;
  color: #65451f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const SignUpLink = styled.span`
  margin-top: 10px;
  cursor: pointer;
  color: #1C3879;
`;

const ForgotPassword = styled.div`
  width:100%;
  margin-top: 5px;
  cursor:pointer;
  color: #1C3879;
  // background-color:red;
  display:flex;
  justify-content:flex-end;
  margin-right:-19px;
`

export default LoginForm;
