import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userRequest } from '../requestMethods';

function TestPayment() {
    const [amount, setAmount] = useState('');
    const user = useSelector((state) => state.user.currentUser);

    const handleCheckout = () => {
        const bookingFormData = {
            "serviceData": [
                {
                    "categoryName": "Brooming and Mopping",
                    "optionsData": {
                        "houseSize": {
                            "optionName": "2BHK",
                            "optionPrice": 500
                        },
                        "floors": {
                            "optionName": "1 Floor",
                            "optionPrice": 0
                        }
                    },
                    "servicePrice": 1500
                }
            ],
            "bookingTimings": {
                "serviceTiming": "",
                "startDate": ""
            },
            "totalPrice": parseInt(amount, 10)  // Use the user-entered amount
        };
        console.log(bookingFormData);

        const data = {
            userId: user._id,
            name: user.name,
            amount: bookingFormData.totalPrice,
            phone: user.phone,
            MUID: user._id,
            transactionId: 'T' + Date.now(),
            bookingData: bookingFormData
        };
    
        const axiosInstance = userRequest(user.accessToken);
    
        axiosInstance.post(`/payment/${user._id}`, {...data})
            .then(res => {
                window.location.href = res.data;
            })
            .catch(error => {
                console.log(error);
            }); 
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <form onSubmit={(e) => {
                e.preventDefault(); // Prevent form submission
                handleCheckout();
            }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px' }}>
                <h1>Test Payment</h1>
                <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Enter amount"
                    required
                    style={{ padding: '10px', margin: '10px 0', width: '100%', fontSize: '16px' }}
                />
                <button type="submit" style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', backgroundColor: '#4477CE', color: 'white', border: 'none', borderRadius: '5px' }}>
                    Pay
                </button>
            </form>
        </div>
    );
}

export default TestPayment;
