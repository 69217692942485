import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { register, sendEmailOtp } from '../redux/apiCalls';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetOthers } from '../redux/userRedux';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SignUpForm = ({ onSignUp, onSwitchForm}) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState(''); 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtpForm, setShowOtpForm] = useState(false);
    const [timer, setTimer] = useState(30);
    const [timerActive, setTimerActive] = useState(false);

  
    const navigate = useNavigate();
    
    const message = useSelector((state) => state.user.message);
  const error = useSelector((state) => state.user.error);
  const isFetching = useSelector((state) => state.user.isFetching);
  const user = useSelector((state) => state.user.currentUser)
  //timer
  useEffect(() => {
    let interval;

    if (timerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 0) {
            clearInterval(interval);
            setTimerActive(false);
            return 30; // Reset timer to 30 seconds
          } else {
            return prevTimer - 1;
          }
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timerActive]);
//opt
  useEffect(() => {
    // if (error) {
    //   toast.error(message);
    // }
    if (message === 'OTP Sent') {
        if(!showOtpForm)setShowOtpForm(true);
        setTimerActive(true);
        toast.success('OTP Sent to E-mail ID');
    }
    // if (user) {
    //   navigate('/profile');
    // }
    // dispatch(resetOthers());
  }, [message]);

    // const [user, setUser] = useState(null);
    const dispatch = useDispatch();

    const handleSendOtp=(e)=> {
        e.preventDefault();
        if (!name || !email || !phone || !password || !confirmPassword) {
            // window.alert('Please fill all the fields');
            toast.warn('Please Fill all the fields');
        } else {
            if ( password !== confirmPassword) {
                // window.alert('password and confirm password do not match');
                toast.warn("Password and Confirm Password do not match");
            } else {
                // setLoading(true);
                sendEmailOtp(dispatch, { email });
                
                // setShowOtpForm(true);
            }
        }
    }

    function handleVerifyOtp() {
        // setLoading(true);
        register(dispatch, { name, phone, email, password, otp })
        handleSignUp();
    }

    const handleSignUp = () => {
        // Handle sign-up logic
        onSignUp(name, phone, email, otp);
    };

    return (
        <>
            {/* <ToastContainer limit={1} draggable={false} transition={Zoom} position={toast.POSITION.TOP_CENTER}/> */}
            <Form>
                <FormInput
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <FormInput
                    type="tel"
                    placeholder="Mobile Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <FormInput
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <FormInput
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <FormInput
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {showOtpForm && (
                    <FormInput
                        type="text"
                        placeholder="OTP"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                    />
                )}
                {!showOtpForm ? (
                    <FormButton type="button" onClick={handleSendOtp}>
                        Send OTP
                    </FormButton>
                ) : (
                    <>
                    {timerActive?<ResendOtp>resend in 00:{timer<10?`0${timer}`:timer}</ResendOtp>:<ResendOtp onClick={handleSendOtp}>resend OTP</ResendOtp>}
                    <FormButton type="button" onClick={handleVerifyOtp}>
                        Sign Up
                    </FormButton>
                    </>

                )}
                <LoginLink onClick={onSwitchForm}>Or Login</LoginLink>
            </Form>
        </>
    );
};
const ResendOtp = styled.div`
  width:100%;
//   margin-top: 5px;
  cursor:pointer;
  color: #1C3879;
  // background-color:red;
  display:flex;
  justify-content:flex-end;
  margin-right:-19px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormInput = styled.input`
    padding: 15px;
  width: 100%;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
`;

const FormButton = styled.button`
  margin: 20px;
  padding: 15px 20px;
  font-size: 15px;
  background-color: #eac696;
  color:#65451f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const LoginLink = styled.span`
  margin-top: 10px;
  cursor: pointer;
  color: #1C3879;
`;

export default SignUpForm;
