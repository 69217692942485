import React from 'react'
import styled from 'styled-components';
import { mobile } from '../responsive';
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
`;

const TableHead = styled.thead`
  background: #f9f5eb;
  font-weight: bold;
`;

const TableHeaderCell = styled.th`
  padding: 10px;
  text-align: center;
  color: black;
`;

const SerialTableCell = styled.td`
  // background-color: red;
  width: 5vw; /* Ensure this line is uncommented */
  height: auto;
  text-align: center;
  padding: 10px;
  border-left: 1px solid #eac696;
  border-bottom: 1px solid #eac696;
`;

const TableCell = styled.td`
  width: 15vw;
  // text-align: center;
  padding: 10px;
  border-left: 1px solid #eac696;
  border-bottom: 1px solid #eac696;
  border-right: 1px solid #eac696;
`;

const TableContainer = styled.div`
  margin-top: 16px;
  box-sizing: border-box;
  padding-left: 80px;
  padding-right: 80px;
  width: 100%;
  height: auto;
  ${mobile({ padding: '0px'})}
`;

const TableHeading = styled.div`
// margin-top:25px;
display:flex;
justify-content:center
`
function SubTable() {
    const TableContent = [['2 House help replacements Available','3 House help replacements Available','4 House help replacements Available','6 House help replacements Available'],['Pause and Resume service at any time','Pause and Resume service at any time','Pause and Resume service at any time','Pause and Resume service at any time'],['Quick and Top-Rated Domestic Help Service Available','Quick and Top-Rated Domestic Help Service Available','Quick and Top-Rated Domestic Help Service Available','Quick and Top-Rated Domestic Help Service Available'],['Police Verification Will Be Provided','Police Verification Will Be Provided','Police Verification Will Be Provided','Police Verification Will Be Provided'],[`If We Don't Provide a Replacement, You'll Get a Refund
    `,`If We Don't Provide a Replacement, You'll Get a Refund
    `,`If We Don't Provide a Replacement, You'll Get a Refund
    `,`If We Don't Provide a Replacement, You'll Get a Refund
    `],['Offers Provided (Additional Services Available with Special Offers)','Offers Provided (Additional Services Available with Special Offers)','Offers Provided (Additional Services Available with Special Offers)','Offers Provided (Additional Services Available with Special Offers)']]
  return (
    <>
          <TableHeading>
        <h1>Subscription Plan</h1>
      </TableHeading>
    <TableContainer>
      <Table>
          <TableHead>
            <tr>
              <TableHeaderCell>S.No.</TableHeaderCell>
              <TableHeaderCell>1 Month(₹999)</TableHeaderCell>
              <TableHeaderCell>3 Months(₹2499)</TableHeaderCell>
              <TableHeaderCell>6 Months(₹4499)</TableHeaderCell>
              <TableHeaderCell>12 Months(₹8499)</TableHeaderCell>
            </tr>
          </TableHead>
          <tbody>
            {TableContent?.map(((row,index)=>(
            <tr key={index}>
              <SerialTableCell>{index+1}.</SerialTableCell>
              {row?.map((cells,ind)=>(
                <>
            <TableCell>{cells}</TableCell>
            </>
              ))}

          </tr>
            )))}

          </tbody>
        </Table>
        <p>*Note - These Subscription Pricing is for Single Service</p>
        </TableContainer>
    </>
  )
}

export default SubTable
