import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PersonalInfo from '../components/PersonalInfo';
import Addresses from '../components/Addresses';
import Bookings from '../components/Bookings';


const Profile = () => {
  return (
    <div>
      <Navbar />
      <PersonalInfo />
      <Addresses />
      <Bookings />
      <Footer />
    </div>
  );
};

export default Profile;
