import React from 'react';
import styled from 'styled-components';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { mobile } from '../responsive';

const Whatsap = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  box-sizing: border-box;
  padding-top: 5px;
  justify-content: center;
  align-items: center;
  background-color: #25D366;
  z-index:999;

  ${mobile({
    left:'10px',
    right:'auto',
    borderRadius: '50%',
  })}
`;

function Whatsapp() {
  return (
    <Whatsap>
      <a href="https://wa.me/919818102998">
        <WhatsAppIcon style={{ fontSize: '50px', color: 'white' }} />
      </a>
    </Whatsap>
  );
}

export default Whatsapp;
