import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
// import Spinner from './Spinner';
import { mobile } from '../responsive';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Icons from '../icons';
import { useDispatch, useSelector } from 'react-redux';
import { resetOthers } from '../redux/userRedux';

const { CloseIcon } = Icons;


const LogoutPopup = ({ isOpen, onClose,logoutTrue }) => {
  const [isForgot,setIsForgot] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigationFlag = useSelector(state=>state.user.navigationFlag);
  const message = useSelector((state) => state.user.message);
  const error = useSelector((state) => state.user.error);
  const isFetching = useSelector((state) => state.user.isFetching);
  const user = useSelector((state) => state.user.currentUser)

  // Add or remove the 'no-scroll' class on the body element when the popup opens or closes.
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    // Clean up the class when the component unmounts.
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  const handleLogout = () =>{
    logoutTrue();
  }

  return (
    <PopupOverlay isOpen={isOpen}>
      {/* {isFetching && <Spinner />} */}
      {/* <ToastContainer draggable={false} transition={Zoom} position={toast.POSITION.TOP_CENTER}/> */}
      <PopupContent>
        <CloseButton onClick={()=>{
          onClose();
        }}><CloseIcon style={ {width: '15px', height: '15px',} }/></CloseButton>
        <LoginHeader>Are you sure You want to Logout ?</LoginHeader>
        <ButtonContainer>
        <FormButton onClick={handleLogout}>
          Yes
        </FormButton>
        <FormButton  onClick={onClose}>
          No
        </FormButton>
        </ButtonContainer>

      </PopupContent>
    </PopupOverlay>
  );
};

const PopupOverlay = styled.div`
   display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.35); 
  backdrop-filter: blur(6px); 
  -webkit-backdrop-filter: blur(6px); 
  z-index: 9999;
  -webkit-animation: fadeIn 0.25s linear; 
  animation: fadeIn 0.25s linear; 
`;

const PopupContent = styled.div`
  display:flex;
  align-items:center;
  flex-direction:column;
  background-color: #fff;
  width: 500px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${mobile({width: "70vw"})}
`;

const CloseButton = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background-color: #eac696;
  color: #65451f;
  font-size: 10vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
width:100%;
display:flex;
justify-content:center;
`

const FormButton = styled.button`
  margin: 20px;
  width:90px;
  height:38px;
//   padding: 15px 20px;
  font-size: 15px;
  background-color: #eac696;
  color: #65451f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const LoginHeader = styled.h3`
  font-size: 23px;
  margin-bottom: 20px;
  margin-left: 37px;
`;

export default LogoutPopup;
