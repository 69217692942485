// import { allServices } from "../data";
import { useEffect} from "react";
import styled from "styled-components";
import ServiceCard from "./ServiceCard";
import { mobile } from "../responsive";
// import { publicRequest } from "../requestMethods";
import { useSelector,useDispatch } from "react-redux";
import { getAllServices } from "../redux/apiCalls";

const Wrapper = styled.div`
  padding: 20px 80px;
  ${mobile({ padding: '20px', })}
`

const Title = styled.h1`
  font-weight: 200;
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const AllServicesComponent = () => {
  const allServices = useSelector((state) => state.service.allServices);
  const dispatch = useDispatch();
  useEffect(()=>{
    getAllServices(dispatch);
  },[dispatch]);
  return (
    <Wrapper>
    <Title>Our Services</Title>
    <Container>
      {allServices && allServices.map((item) => (
        <ServiceCard item={item} key={item._id} />
      ))}
    </Container>
    </Wrapper>
  );
};

export default AllServicesComponent;