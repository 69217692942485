import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import Spinner from './Spinner';
import { mobile } from '../responsive';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userRequest } from '../requestMethods';
import 'react-toastify/dist/ReactToastify.css';
import Icons from '../icons';
import { useDispatch, useSelector } from 'react-redux';
import { resetOthers } from '../redux/userRedux';

const { CloseIcon } = Icons;

const SubscriptionPopup = ({ isOpen, onClose, bid }) => {
  const [plan, setPlan] = useState('1 Month');
  const [cost, setCost] = useState(1178.82);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigationFlag = useSelector(state => state.user.navigationFlag);
  const message = useSelector((state) => state.user.message);
  const error = useSelector((state) => state.user.error);
  const isFetching = useSelector((state) => state.user.isFetching);
  const user = useSelector((state) => state.user.currentUser);

  const calculateTotalCost = (baseCost) => {
    return baseCost * 1.18;
  };

  const handleChooseSubscription = (plan, value) => {
    setPlan(plan);
    setCost(calculateTotalCost(value));
  };

  const handleCheckout = () => {
    const data = {
      bookingId: bid,
      userId: user._id,
      name: user.name,
      amount: cost,
      phone: user.phone,
      MUID: user._id,
      subscriptionAmount: cost.toFixed(2),
      subscriptionFor : plan,
      transactionId: 'T' + Date.now(),
    };

    const axiosInstance = userRequest(user.accessToken);

    axiosInstance.patch(`/payment/${user._id}`, { ...data })
      .then(res => {
        window.location.href = res.data;
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return (
    <PopupOverlay isOpen={isOpen}>
      <PopupContent>
        <CloseButton onClick={onClose}><CloseIcon style={{ width: '15px', height: '15px' }} /></CloseButton>
        <SubsText>Subscription</SubsText>
        <SubsButton>
          <ButtonDiv selected={plan === '1 Month'} onClick={() => handleChooseSubscription('1 Month', 999)}><p>1 Month</p></ButtonDiv>
          <ButtonDiv selected={plan === '3 Months'} onClick={() => handleChooseSubscription('3 Months', 2499)}><p>3 Months</p></ButtonDiv>
          <ButtonDiv selected={plan === '6 Months'} onClick={() => handleChooseSubscription('6 Months', 4499)}><p>6 Months</p></ButtonDiv>
          <ButtonDiv selected={plan === '12 Months'} onClick={() => handleChooseSubscription('12 Months', 8499)}><p>12 Months</p></ButtonDiv>
        </SubsButton>
        <PlanDetails>
          <PlanText>Subscription for: {plan}</PlanText>
          <PlanText>Subscription Cost: ₹ {cost.toFixed(2)} (included GST) </PlanText>
        </PlanDetails>
        <CheckoutButton><CheckoutText onClick={handleCheckout}>Checkout</CheckoutText></CheckoutButton>
      </PopupContent>
    </PopupOverlay>
  );
};

const PopupOverlay = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.35);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  z-index: 9999;
  -webkit-animation: fadeIn 0.25s linear;
  animation: fadeIn 0.25s linear;
`;

const PopupContent = styled.div`
  background-color: #fff;
  width: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${mobile({ width: "70vw" })}
`;

const SubsText = styled.h1`
  color: #4477CE;
  margin-left:28%;
`;

const CheckoutButton = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckoutText = styled.p`
  cursor: pointer;
  width: 100px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4477CE;
  color: white;
  font-size: 18px;
  border-radius: 4px;
`;

const PlanDetails = styled.div`
  width: 100%;
  margin-top: 20px;
  flex-direction: column;
  height: auto;
  display: flex;
  align-items: flex-start;
`;

const PlanText = styled.div`
  font-size: 17px;
  margin-left: 17px;
  margin-top: 20px;
`;

const SubsButton = styled.div`
  display: flex;
  justify-content: space-around;
  ${mobile({ flexDirection: "column" })}
`;

const ButtonDiv = styled.div`
  cursor: pointer;
  width: 90px;
  background-color: ${(props) => (props.selected ? '#4477CE' : 'rgb(204, 204, 204)')};
  color: ${(props) => (props.selected ? 'white' : 'black')};
  height: 40px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  ${mobile({ marginTop: "7px", marginLeft: '10px;' })}
`;

const CloseButton = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 25px;
  height: 25px;
  background-color: #4477CE;
  color: #fff;
  font-size: 14vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default SubscriptionPopup;
