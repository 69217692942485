import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import { mobile } from "../responsive"; 


const Container = styled.div`
    border: 2px solid #b99470;
    margin: 30px 0;
    min-width: 250px;
    height: 260px;
    /* border-radius: 15px; */
    /* background-color: #f5fbfd; */
    position: relative;
    cursor: pointer;
    /* overflow: hidden; */

    &:hover {
      opacity: .5;
    }
  `;

const ImageContainer = styled.div`
  position: absolute;
  top: -15px;
  right: 15px;
  left: 15px;
  width: 220px;
  height: 220px;
`

const Image = styled.img`
  position: relative;
  top: 0;
  right: 0;
  width: 220px;
  height: 220px;
  object-fit:cover;
  border-top-left-radius:5px;
  border-top-right-radius:5px;
  z-index: 2;
  `;

const TitleContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  align-items: center;
  text-align: center;
`

const Title = styled.h2`
    margin: 12px;
    ${mobile({ fontWeight: '200' })}
`


const ServiceCard = ({ item }) => {
    const navigate = useNavigate()
    return (
      
        <Container onClick={() => navigate(`/services/${item._id}`)}>
          <ImageContainer>
            <Image src={item?.image || "https://media.licdn.com/dms/image/D5603AQEW-g3BNmsRLw/profile-displayphoto-shrink_800_800/0/1700204649863?e=2147483647&v=beta&t=v_zykYE9LuLcSzzGdILknuC83_4u6hQr738YmNuRSp8"} />
          </ImageContainer>
          <TitleContainer>
            <Title>{item?.service}</Title>
          </TitleContainer>
        </Container>
    );
};

export default ServiceCard;