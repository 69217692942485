import { loginFailure, loginStart, loginSuccess, sendOtpSuccess } from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";
import { getServices,serviceFetching } from "./serviceRedux";
import { feedBookings } from "./bookingRedux";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess({user:res.data,msg:'Login Success'}));
  } catch (err) {
    dispatch(loginFailure(err.response.data));
  }
};
export const getAllServices = async(dispatch)=>{
  dispatch(serviceFetching());
  try{
    const res = await publicRequest.get('/service');
    dispatch(getServices(res.data));
  }catch(err){

  }
}
export const register = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/register", user);
    dispatch(loginSuccess({user:res.data,msg:'Login Success'}));
  } catch (err) {
    dispatch(loginFailure(err.response.data));
  }
};

export const sendEmailOtp = async (dispatch, email) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/send-email-otp", email);
    dispatch(sendOtpSuccess(res.data));
  } catch (err) {
    console.log(err);
    dispatch(loginFailure(err.response.data));
  }
};

export const editUserDetails = async (dispatch, userId, token, newDetails) => {
  const axiosInstance = userRequest(token);
  try {
    const res = await axiosInstance.put(`/users/${userId}`, newDetails);
    dispatch(loginSuccess({user : res.data,msg:'Details Changed Successfully'}));
  } catch (err) {
    console.log(err);
  }
};

export const addAddress = async (dispatch, userId, token, address) => {
  const axiosInstance = userRequest(token);
  try {
    const res = await axiosInstance.post(`/users/add-address/${userId}`, address);
    dispatch(loginSuccess({user: res.data,msg:'Added New Address'}));
  } catch (err) {
    console.log(err);
  }
};

export const editAddress = async (dispatch, userId, token, addressIndex, updatedAddress) => {
  const axiosInstance = userRequest(token);
  try {
    const res = await axiosInstance.put(`/users/edit-address/${userId}`, {addressIndex, updatedAddress});
    dispatch(loginSuccess({user:res.data,msg:'Address Changed'}));
  } catch (err) {
    console.log(err);
  }
};

export const deleteAddress = async (dispatch, userId, token, addressIndex) => {
  const axiosInstance = userRequest(token);
  try {
    const res = await axiosInstance.delete(`/users/delete-address/${userId}`, addressIndex);
    dispatch(loginSuccess({user:res.data,msg:'Address deleted'}));
  } catch (err) {
    console.log(err);
  }
};

export const getBookingDetails = async (dispatch, userId, bookingId, token) => {
  const axiosInstance = userRequest(token); // Assuming the booking details are protected and require a user token
  try {
    const res = await axiosInstance.get(`/bookings/detail/${userId}/?bookingId=${bookingId}`);
    return res.data;
    // dispatch(loginSuccess(res.data));
  } catch (err) {
    console.log(err);
    // dispatch(loginFailure(err.response.data));
  }
};

export const getUserBookings = async (dispatch,userId,token)=>{
  const axiosInstance = userRequest(token);
  try{
    const res = await axiosInstance.get(`/bookings/user/${userId}`);
    dispatch(feedBookings(res.data));
  }catch(err){
    console.log(err);
  }
};
