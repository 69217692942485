import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { mobile } from '../responsive';
import { useSelector, useDispatch } from 'react-redux';
import { format, addDays } from 'date-fns';
import { getUserBookings } from '../redux/apiCalls';
import SubscriptionPopup from './SubscriptionPopup';

const Container = styled.div`
  width: 80vw;
  padding: 50px;
  margin: 20px 80px;
  background-color: #f9f5eb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  ${mobile({ padding: '20px', margin: '20px 10px' })}
`;

const Wrapper = styled.div`
  width: 80vw;
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 25px 10px;
  background-color: #f9f5eb;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  ${mobile({ paddingTop: '20px', paddingBottom: '20px', margin: '20px 0px' })}
`;

const KeyContainer = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  box-sizing: border-box;
  padding-left: 40px;
  margin-top: -4px;
  ${mobile({ paddingLeft: '0px', flexDirection: 'column', marginTop: '-11px' })}
`;

const Heading = styled.h1`
  margin: 0;
  font-weight: 200;
  ${mobile({ fontSize: '30px' })}
`;

const ValueContainer = styled.p`
  font-size: 20px;
  margin-left: 7px;
  margin-right: 7px;
`;

const Button = styled.button`
margin-bottom : 10px;
background-color:#eac696;
border:none;
border-radius:3px;
width:150px;
height:40px;
color:#65451f;
font-size:18px;
cursor:pointer;
`

const Bookings = () => {
  const dispatch = useDispatch();
  const [isPopup,setIsPopup] = useState(false);
  const bookings = useSelector(state => state.booking.bookings);
  const token = useSelector(state => state.user?.currentUser?.accessToken);
  const userId = useSelector(state => state.user.currentUser?._id);
  const [bookingId,setBookingId] = useState();

  useEffect(() => {
    getUserBookings(dispatch, userId, token);
  }, [dispatch, userId, token]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return format(date, 'MMMM d, yyyy');
  };
 const handlePopup = (id)=>{
  setIsPopup(true);
  setBookingId(id);
 }
  const getTrailEndDate = (startDate) => {
    if (!startDate) return '';
    const date = new Date(startDate);
    const trailEndDate = addDays(date, 2);
    return format(trailEndDate, 'MMMM d, yyyy');
  };

  const handleOnClose = () =>{
    setIsPopup(false);
    setBookingId();
  }

  return (
    <>
    <Container>
      <Heading>My Bookings</Heading>
      {bookings?.map((booking, index) => (
        <Wrapper key={index}>
          <KeyContainer>
            <h3>Booking Id : </h3>
            <ValueContainer>{booking?._id}</ValueContainer>
          </KeyContainer>
          <KeyContainer>
            <h3>Service : </h3>
            {booking.services?.map((category, ind) => (
              <ValueContainer key={ind}>[{category?.categoryName}]</ValueContainer>
            ))}
          </KeyContainer>
          <KeyContainer>
            <h3>Starts From : </h3>
            <ValueContainer>{formatDate(booking?.startDate)}</ValueContainer>
          </KeyContainer>
          {!booking?.onTrail && (
            <>
            <KeyContainer>
              <h3>Amount Paid : </h3>
              <ValueContainer>₹{booking?.totalPrice}</ValueContainer>
            </KeyContainer>
            <KeyContainer>
              <h3>Subscription Amount : </h3>
              <ValueContainer>₹{booking?.subscriptionAmount}</ValueContainer>
            </KeyContainer>
            <KeyContainer>
              <h3>Subscription For : </h3>
              <ValueContainer>{booking?.subscriptionFor}</ValueContainer>
            </KeyContainer>
            </>
          )}
          <KeyContainer>
            <h3>Status : </h3>
            <ValueContainer>{booking?.bookingStatus}</ValueContainer>
          </KeyContainer>
          {booking?.onTrail && (
            <KeyContainer>
              <h3>Trial Ends On : </h3>
              <ValueContainer>{getTrailEndDate(booking?.startDate)}</ValueContainer>
            </KeyContainer>
          )}
           {booking?.onTrail && <KeyContainer>
            <Button onClick={()=>handlePopup(booking?._id)}>Add Subscription</Button>
           </KeyContainer>}
           
        </Wrapper>
        
        
      ))}
      
    </Container>
    <SubscriptionPopup bid={bookingId}  isOpen={isPopup} onClose={handleOnClose} />
    </>
  );
};

export default Bookings;
