import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoginPopup from "./LoginPopup";
import { mobile } from "../responsive";
import logo from "../assets/images/logo.png"
import { loginNavigationSet, resetUser } from "../redux/userRedux";
import { useDispatch, useSelector } from "react-redux";
import LogoutPopup from "./LogoutPopup";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { resetBookings } from "../redux/bookingRedux";


function Navbar() {
  const [extendNavbar, setExtendNavbar] = useState(false);
  const [isLogoutPopupOpen,setIsLogoutPopupOpen] = useState(false);
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false); // State to control the login popup
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openLoginPopup = () => {
    dispatch(loginNavigationSet());
    setLoginPopupOpen(true);
  };

  const closeLoginPopup = () => {
    setLoginPopupOpen(false);
  };
  const closeLogoutPopup = () => {
    setIsLogoutPopupOpen(false);
  };

  const handleLogout = () => {
    setIsLogoutPopupOpen(true);
    // dispatch(resetUser());
    // navigate('/');
  }

  const handleTrueLogout = () =>{
    setIsLogoutPopupOpen(false);
    toast.success('Logout successfully');
    dispatch(resetBookings());
    dispatch(resetUser());
    navigate('/');
  }

  return (
    <NavbarContainer extendNavbar={extendNavbar}>
      <NavbarInnerContainer>
        <LeftContainer>
          <Logo src={logo} alt=''></Logo>
        </LeftContainer>
        <RightContainer>
          <NavbarLinkContainer>
            <NavbarLink to="/"> Home</NavbarLink>
            <NavbarLink to="/services/all">All Services</NavbarLink>
            <NavbarLink to="/subscription/details/all">Subscription</NavbarLink>
            <NavbarLink to="/contact"> Contact Us</NavbarLink>
            <NavbarLink to="/about"> About Us</NavbarLink>
            {/* <NavbarLink to="/maid-service-near-me">Service Near Me</NavbarLink> */}
            {user &&
              <NavbarLink to="/profile">My Profile</NavbarLink>
            }
            <OpenLinksButton
              onClick={() => {
                setExtendNavbar((curr) => !curr);
              }}
            >
              {extendNavbar ? <>&#10005;</> : <> &#8801;</>}
            </OpenLinksButton>
          </NavbarLinkContainer>
          {user ? (
            <LoginButton onClick={handleLogout}>Logout</LoginButton>
          ) : (
            <LoginButton onClick={openLoginPopup}>Login</LoginButton>
          )}
        </RightContainer>

      </NavbarInnerContainer>
      {extendNavbar && (
        <NavbarExtendedContainer>
          {user ? (
            <LoginButtonExtended onClick={handleLogout}>Logout</LoginButtonExtended>
          ) : (
            <LoginButtonExtended onClick={openLoginPopup}>Login</LoginButtonExtended>
          )}
          {user &&
            <NavbarLinkExtended to="/profile">My Profile</NavbarLinkExtended>
          }
          <NavbarLinkExtended to="/"> Home</NavbarLinkExtended>
          <NavbarLinkExtended to="/services/all">All Services</NavbarLinkExtended>
          <NavbarLinkExtended to="/subscription/details/all">Subscription</NavbarLinkExtended>
          <NavbarLinkExtended to="/contact"> Contact Us</NavbarLinkExtended>
          <NavbarLinkExtended to="/about"> About Us</NavbarLinkExtended>
          {/* <NavbarLinkExtended to="/maid-service-near-me">Service Near Me</NavbarLinkExtended> */}
        </NavbarExtendedContainer>
      )}
      <LogoutPopup logoutTrue={handleTrueLogout} isOpen={isLogoutPopupOpen} onClose={closeLogoutPopup}/>
      <LoginPopup isOpen={isLoginPopupOpen} onClose={closeLoginPopup}/>
    </NavbarContainer>
  );
}

const Icon = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const NavbarContainer = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #fff;
  padding: 0 80px;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* width: 100%; */
  height: ${(props) => (props.extendNavbar ? "100vh" : "70px")};
  display: flex;
  flex-direction: column;
  ${mobile({
  padding: '0px 20px',
  height: (props) => (props.extendNavbar ? "100vh" : "65px")
})}

  /* @media (min-width: 700px) {
    height: 80px;
  } */
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${mobile({ flex: '2' })}
`;

const RightContainer = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({
  flex: '1',
  justifyContent: 'flex-end'
})}
`;

const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
`;

const NavbarLinkContainer = styled.div`
  display: flex;
`;

const NavbarLink = styled(Link)`
  color: #65451f;
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 10px 15px;
  ${mobile({ display: 'none' })}
  
`;

const NavbarLinkExtended = styled(Link)`
  color: #000;
  font-size: x-large;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  margin: 15px;
`;

const Logo = styled.img`
  max-width: 180px;
  height: 80px;

  ${mobile({ maxHeight: '70px' })}
`;

const OpenLinksButton = styled.button`
  width: 70px;
  height: 50px;
  background: none;
  border: none;
  /* color: white; */
  font-size: 45px;
  cursor: pointer;
  display: none;
  ${mobile({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})}

  /* @media (min-width: 700px) {
    display: none;
  } */
`;

const NavbarExtendedContainer = styled.div`
  display: none;
  ${mobile({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})}
`;

const LoginButton = styled.button`
  height: 40px;
  width: 90px;
  color: #65451f;
  font-size: 15px;
  background-color: #eac696;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  ${mobile({ display: 'none' })}
`;

const LoginButtonExtended = styled(LoginButton)`
  width: 150px;
  height: 50px;
  margin: 15px;
  font-size: 20px;
  ${mobile({ display: 'block' })}
  /* @media (max-width: 700px) {
    display: block;
  } */
`;

export default Navbar;
