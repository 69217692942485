import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';
import TrailStrip from '../components/TrailStrip';

export default function About() {
  return (
    <div>
      
      <Navbar />
      <TrailStrip/>
      <AboutUs />
      <Footer />
    </div>
  )
}
