import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SubscriptionPopup from '../components/SubscriptionPopup';
import { getBookingDetails } from '../redux/apiCalls';
import { mobile } from '../responsive';
import Navbar from '../components/Navbar';
import SubTable from '../components/SubTable';

const Success = () => {
  const { bookingId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.user.currentUser);
  const userId = useSelector((state) => state.user.currentUser._id);

  const [bookingDetails, setBookingDetails] = useState(null);
  const [isSubscriptionOpen, setIsSubscriptionOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isPopup, setIsPopup] = useState(false);

  const subTableRef = useRef(null);

  useEffect(() => {
    if (bookingId && accessToken) {
      getBookingDetails(dispatch, userId, bookingId, accessToken)
        .then((data) => {
          setBookingDetails(data);
          setLoading(false);
        })
        .catch((err) => {
          setError('Failed to load booking details');
          setLoading(false);
          console.error('Error fetching booking details:', err);
        });
    }
  }, [dispatch, bookingId, accessToken, userId]);

  useEffect(() => {
    if (isSubscriptionOpen && subTableRef.current) {
      subTableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSubscriptionOpen]);

  if (loading) {
    return <Loader>Loading booking details...</Loader>;
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  const handlePopup = () => {
    setIsPopup(true);
  };

  const handleOnClose = () => {
    setIsPopup(false);
  };

  const scrollToSubTable = () => {
    setIsSubscriptionOpen(true);
  };

  return (
    <>
      <Navbar />
      <Container>
        <Title>{bookingDetails.onTrail ? 'Trial Booking Successful':'Subscription Added'}</Title>
        {bookingDetails && (
          <>
            <DetailTitle>Booking ID: {bookingDetails._id}</DetailTitle>
            <DetailText>Start Date: {bookingDetails.startDate}</DetailText>
            <DetailText>
              Services: {bookingDetails.services.map((item, index) => (
                <span key={index}>{item.categoryName}</span>
              ))}
            </DetailText>
            <DetailText onClick={scrollToSubTable} style={{ cursor: 'pointer', color: '#4477CE', textDecoration: 'underline' }}>
              View Subscription Plan
            </DetailText>
          </>
        )}
        <HomeButton onClick={() => navigate('/')}>Home</HomeButton>
        <HomeButton onClick={handlePopup}>Add Subscription</HomeButton>
        <SubscriptionPopup bid={bookingId} servicePrice={bookingDetails.totalPrice} isOpen={isPopup} onClose={handleOnClose} />
      </Container>
      {isSubscriptionOpen && <div ref={subTableRef}>
        <SubTable />
      </div>}
    </>
  );
};

const Container = styled.div`
  width: 90%;
  max-width: 600px;
  margin-top:130px;
  margin-left:auto;
  margin-right:auto;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  border-radius: 8px;
  text-align: center;
  ${mobile({ padding: '20px 10px', width: '100%' })}
`;

const Title = styled.h1`
  font-weight: 200;
  color: #4477CE;
`;

const DetailTitle = styled.h3`
  margin-top: 20px;
`;

const DetailText = styled.p`
  font-size: 16px;
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
  margin-top: 50px;
`;

const HomeButton = styled.button`
  width: 140px;
  height: 35px;
  margin-top: 10px;
  background-color: #4477CE;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 7px;
`;

export default Success;
