import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import MainServices from '../components/MainServices';
import Choose from '../components/Choose';
import Testimonials from '../components/Testimonials';
import TrailStrip from '../components/TrailStrip';
import Whatsapp from '../components/Whatsapp';
import bannerImage from '../assets/images/bgImg.png';

export default function Home() {
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://www.chamaksaathi.com" />
      </Helmet>
      <TrailStrip />
      <Navbar /> 
      <Banner image={bannerImage} heading="Your Domestic Bliss Starts Here!" para="Cooking, Cleaning, Gardening, Baby Sitting and More." btnText="Call Now" />
      <MainServices />
      <Choose />
      <Testimonials />
      <Footer />
      <Whatsapp />
    </div>
  );
}
