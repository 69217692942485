import React from 'react';
import styled from 'styled-components';
import { mobile } from '../responsive';
import CallIcon from '@mui/icons-material/Call';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
  width: 100%;
  height: 25px;
  background-color: #eac696;
  text-align: center;
  position: relative;
  ${mobile({ flexDirection: 'column', alignItems: 'center', position: 'static', height: 'auto' })}
`;

const TextContainer = styled.h4`
  margin: auto;
  ${mobile({ marginBottom: '10px' })}
`;

const NumberContainer = styled.h4`
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  ${mobile({ position: 'static', margin: 'auto' })}
`;

const Icon = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

function TrailStrip() {
  return (
    <Container>
      <TextContainer>Book your Free 2-Day's Trial!</TextContainer>
      <NumberContainer>
      <Icon>
          <CallIcon /> 
        </Icon>
        Call us at :

        <a style={{'margin-left':'5px',"color":"inherit",textDecoration:"none"}}href="tel:+91-9818102998">+91-9818102998</a>
      </NumberContainer>
    </Container>
  );
}

export default TrailStrip;
