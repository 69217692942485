import React from 'react';
import styled from 'styled-components';
import { mobile } from '../responsive';
import bgnearby from '../assets/images/banner.png';

const Wrapper = styled.div`
  // width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  ${mobile({ flexDirection: 'column', height: 'auto', padding: '20px' })}
`;

const InfoContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mobile({ width: '100%', alignItems: 'flex-start' })}
`;

const ImageContainer = styled.div`
  width: 40%;
  // background-color:red;
  display: flex;
  align-items: center;
  justify-content: center;
  ${mobile({ width: '100%', marginTop: '20px' })}
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  padding: 20px; /* Added padding to ensure equal spacing */
  border: 7px solid #eac696;
  display: flex;
  justify-content: center;
  
  align-items: center; /* Center the image vertically */
  box-sizing: border-box; /* Ensure padding is included in the total width and height */
  ${mobile({padding:"2px",border:'4px solid #eac696'})}
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  box-sizing: border-box; /* Ensure padding is included in the total width and height */
`;

const Title = styled.h1`
  font-size: 52px;
  font-weight: 600;
  margin: 0;
  ${mobile({ fontSize: '28px',fontWeight:"400" })}
`;

const Button = styled.button`
  margin: 20px 0;
  padding: 15px;
  font-size: 20px;
  color: #65451f;
  width: 50%;
  box-shadow: 3px 3px 1px rgba(0, 0, 0, 0.25);
  background-color: #eac696;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  ${mobile({ width: '60%' })}
`;

function NearByBanner() {
  const handleCall = ()=>{
    window.location.href = "tel:9818102998";
  }
  return (
    <Wrapper>
      <InfoContainer>
        <Title>Maid Service Near Me in Gurugram!</Title>
        <Button onClick={handleCall}>Call Now</Button>
      </InfoContainer>
      <ImageContainer>
        <ImageWrapper>
          <Image src={bgnearby} alt="banner" />
        </ImageWrapper>
      </ImageContainer>
    </Wrapper>
  );
}

export default NearByBanner;
