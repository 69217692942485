import React from 'react';
import styled from 'styled-components';
import { choose } from '../data';
import { mobile } from '../responsive';

const Wrapper = styled.div`
  padding: 20px 80px;
  ${mobile({ padding: '20px' })}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`;

const Heading = styled.h1`

`;

const SmallContainer = styled.div`
  margin: 30px 0 !important;
  width: 450px;
  min-width: 280px;
  height: 260px;
  padding: 30px 30px 20px 20px;
  margin: 10px;
  background-color: #f9f5eb;
  position: relative;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);

  /* Pseudo-element for custom "shifted" border */
  &::before {
    content: '';
    position: absolute;
    top: 12px; 
    right: 12px; 
    bottom: -12px; 
    left: -12px; 
    border: 2px solid #b99470; 
    box-sizing: border-box;
  }
`;

const ImageContainer = styled.div`
  height: 30%;
  width: 100%;
  overflow: hidden;
  display: flex;
`;

const Image = styled.img`
  align-self: center;
  height: 100%;
  z-index: 2;
`;

const ContentContainer = styled.div`
  
`;

const Title = styled.h2`
  /* text-align: center;  */
`;

const Desc = styled.p`
  font-size: .9rem;
  text-align: justify;
`;

const Choose = () => {
    return (
        <Wrapper>
            <Heading>Why Choose Us</Heading>
            <Container>
                {choose.map((item) => (
                    <SmallContainer>
                        <ImageContainer>
                            <Image src={item.img} />
                        </ImageContainer>
                        <ContentContainer>
                            <Title>
                                {item.title}
                            </Title>
                            <Desc>
                                {item.desc}
                            </Desc>
                        </ContentContainer>
                    </SmallContainer>
                ))}
            </Container>
        </Wrapper>
    )
}

export default Choose