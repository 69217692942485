// PrivacyPolicy.jsx
import React from 'react';
import styled from 'styled-components';
import { mobile } from '../responsive';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <Container>
        <TopContainer>
          <Title>Privacy Policy</Title>
        </TopContainer>
        <Content>
          <Paragraph>Last updated: 25 March 2024</Paragraph>
          <Paragraph>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from Chamak Saathi (the “Site”).</Paragraph>

          <SectionTitle>Personal Information We Collect</SectionTitle>
          <Paragraph>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information”.</Paragraph>

          <SectionTitle>How We Use Your Personal Information</SectionTitle>
          <Paragraph>We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to communicate with you; screen our orders for potential risk or fraud; and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services. We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).</Paragraph>

          <SectionTitle>Sharing Your Personal Information</SectionTitle>
          <Paragraph>We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Shopify to power our online store--you can read more about how Shopify uses your Personal Information here: https://www.shopify.com/legal/privacy. We also use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout. Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</Paragraph>

          <SectionTitle>Do Not Track</SectionTitle>
          <Paragraph>Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.</Paragraph>

          <SectionTitle>Changes</SectionTitle>
          <Paragraph>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons.</Paragraph>

          <SectionTitle>Contact Us</SectionTitle>
          <Paragraph>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at privacy@chamaksaathi.com or by mail using the details provided below: Chamak Saathi, [Your Address].</Paragraph>
        </Content>
      </Container>
      <Footer />
    </div>
  );
};

const Container = styled.div`
  width: 90%;
  padding: 70px 80px 30px 80px;
  margin: 20px auto;
  ${mobile({ padding: '30px 10px', width: '100%' })}
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 40px;
  margin: 0;
`;

const Content = styled.div`
  margin: 20px 0;
`;

const SectionTitle = styled.h3`
  font-weight: 500;
  margin: 20px 0 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 10px;
`;

export default PrivacyPolicy;
