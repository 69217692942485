// import { allServices } from "../data";
import { useEffect } from "react";
import styled from "styled-components";
import ServiceCard from "./ServiceCard";
import { Link } from "react-router-dom";
import { mobile } from "../responsive";
import { useDispatch,useSelector } from "react-redux";
import { getAllServices } from "../redux/apiCalls";

const Wrapper = styled.div`
  padding: 40px 80px;
  ${mobile({ padding: '20px', })}
`;

const Title = styled.h1`
  font-weight: 700;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${mobile({ justifyContent: 'center' })}
`;

const ButtonContainer = styled.div`
  text-align: center;
`;


const Button = styled.button`
  padding: 15px 25px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  color: #65451f;
  background-color: #eac696;
  border: none;
  border-radius: 15px;
`;

const Loading = styled.div`
display:flex;
justify-content : center;

`;

const MainServices = () => {
  const allServices = useSelector((state) => state.service.allServices);
  const isFetching = useSelector((state) => state.service.isFetching);
  const dispatch = useDispatch();
  useEffect(()=>{
    getAllServices(dispatch);
  },[dispatch]);
  return (
    <Wrapper>
    <Title>Our Services</Title>
    
    <>
    <Container>
      {allServices.slice(0, 4).map((item) => (
        <ServiceCard item={item} key={item._id} />
      ))}
    </Container>
    <Link to='/services/all'>
      <ButtonContainer>
        <Button>
          and More...
        </Button>
      </ButtonContainer>
    </Link>
    </>
    </Wrapper>
  );
};

export default MainServices;